.n-wrapper {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  top:0;
  position: sticky;
  position: fixed;
  background-color: rgb(255, 251, 251);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  z-index: 99999;
  width: 92%;
  border-radius: 20px;
}
.n-left {
  flex: 0.5;
  align-items: center;
  justify-content: left;
  display: flex;
  gap: 2rem;
}

.n-name {
  font-size: 1.3rem;
  font-weight: bold;
}
.n-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
}

.n-list {
  flex: 10;
}
.n-list > ul {
  display: flex;
  gap: 2rem;
  margin-right: 1rem;
}

.n-list > ul :hover {
  color: var(--orange);
  cursor: pointer;
}

.n-button {
  flex: 2;
}

@media screen and (max-width: 480px) {
  .n-list {
    display: none;
  }
}

